.CustomerInfoContainer {
    padding: 20px;
}

.CustomerInfoSection {
    background-color: #d9e7fd;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.CustomerInfoSection h3 {
    margin-top: 0;
}

.CustomerInfoSection label {
    display: block;
    margin: 10px 0 5px;
}

.CustomerInfoInput {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.CustomerInfoInput[type="color"] {
    padding: 0;
    height: 30px;
}