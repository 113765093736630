/* Styles for the container div */
.viewer-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full viewport height */
  width: 100vw;
  /* Full viewport width */
  background-color: #555555;
}

.viewer-selects-list {
  display: inline-block;
  align-self: flex-end;
}

/* Styles for the centered div */
.viewer-centered-div {
  display: flex;
  flex-direction: column;
  width: 700px;
  min-height: 600px;
  /* Additional styling, like background, padding, etc. */
  background-color: white;
  /* Example background color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Example shadow for a card-like appearance */
  border-radius: 2em;
  padding: 20px;
  box-sizing: border-box;
  justify-content: space-between;
}

.viewer-navigation-div {
  width: 700px;
  top: -80px;
}

.viewer-select-button {
  background-color: darkgray;
  margin: 4px;
  border-color: #000000;
  border-width: 5px;
  border-radius: 40px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Example shadow for a card-like appearance */
}

button,
.Button {
  padding: 12px 24px;
  /* Larger buttons for easier clicking */
  font-size: 1rem;
  /* Larger font size for readability */
}

/* General Styles for Mobile Devices */
@media (max-width: 768px) {
  .viewer-center-container {
    flex-direction: column;
    /* Stack elements vertically */
    padding: 0;
    /* Add some padding */
    align-items: stretch;
    /* Stretch items to use available width */
  }

  .viewer-centered-div {
    width: 100%;
    /* Full width */
    height: 100%;
    box-shadow: none;
    /* Remove shadow to fit better in smaller screens */
    border-radius: 0px;
    padding: 10px;
  }

  .viewer-navigation-div {
    width: 100%;
    /* Full width */
    position: static;
    /* Reset any absolute positioning */
    top: 0px
  }

  .app-body,
  .mainComponent {
    left: 0;
    /* Reset left positioning */
    width: 100%;
    /* Full width */
    min-width: 100%;
  }
}