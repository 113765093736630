html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
/* Main App container styling */
.app {
  display: block;
  flex-direction: column;
  height:100%;
  width: 100%;
  min-width:100%;
}


/* TopBar full width at the top */
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #DDDDFF; /* Your chosen color */
  color: white;
  padding: 10px 20px;
  width: 100%;
}

/* Body container for Sidebar and MainView */
.app-body {
  position:absolute;
  display: flex;
  flex-grow: 1;
  height: calc(100% - 60px); /* Assuming TopBar height is 60px */
  width: 100%;
  min-width:100%
}

.mainComponent{
  position: absolute;
  left: 220px;
  top: 0px; /* Height of the TopBar */
  width: calc(100% - 240px);
  height: calc(100vh - 80px); /* Viewport height minus TopBar height */
  background-color: white;
}


/* Sidebar styling */
.sidebar {
  width: 200px; /* Adjust width as needed */
  background-color: #f0f0f0; /* Your chosen color */
  min-height: 100% !important;
  height: 100%; /* Take full height of the remaining space */
  padding:0px;
}



.flowList {
  display:block;
  flex-direction: row;
}

.flowTableHeadRow{  
  background-color:black;
}

.dataComponent {
  background-color: aqua;
}

.mediaComponent {
  background-color: #f0f0f0;
}

.viewComponent{
  display: block;
  position:relative;
  width: 600px;
  height: auto;
  background-color: #f0f0f0;
}

.typo_title{
  position:absolute;
  left: 10px;
  top: 10px;
  width: 80px;
}


.tf_title{
  position:absolute;
  height:small;
  padding: 2px;
  margin: 2px;
  left:90px;
  top:-10px;
  width:480px;
  background-color: white;
}

.flowTitleComponent{
  position:relative;
  top:0px;
  width:60%;
  max-height:30px;
  background-color: #f0f0f0;
}

.flowHeaderComponent{
  position:relative;
  flex-shrink: 0;
  float:left;
  left:0px;
  top:0px;
  height:80px;
  width:100%;
  background-color: #f0f0f0;

}

.flowDescriptionComponent{
  position:relative;
  flex-shrink: 0;
  float:left;
  left:0px;/*TODO*/
  top:0px;
  width:300px;
  height:200px;
  background-color: #f0f0f0;  
}

.flowArea {
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure it fills its parent */
}
.flowEditorArea{
  position:relative;
  flex-grow: 1; /* Grow to take available space */
  overflow: scroll;
  background-color: dimgrey;
}

.buttonContainer {
  position: absolute;
  top: 90px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  display: flex;
  gap: 10px; /* Space between buttons */
}

.actionButton {
  background-color: white !important; /* Solid white background */
  border-radius: 5px;
  padding: 5px; /* Slight padding for the border-radius effect */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8); /* Optional: add some shadow for better visibility */
  z-index: 10; /* Ensure buttons hover over other elements */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px; /* Adjust size as needed */
  height: 58px; /* Adjust size as needed */
  border: 1px solid #ccc; /* Optional: add a border for better visibility */
}

.actionButton svg {
  width: 32px; /* Icon size */
  height: 32px; /* Icon size */

}

.flowShareArea{
  position:relative;
  flex-grow: 1; /* Grow to take available space */
  overflow: scroll;
  background-color: #f0f0f0;
}

.viewComponent{
    box-shadow: 5px 5px #555555;
    border: solid;
    border-style: solid;
    border-width: 4px;
    border-color: #888888;
    border-radius: 4px;
    margin: 20px;
}


.media_title {
  font-weight: bold;
  display: block;
  text-align: center;
}

.media_uploadDate {
  font-weight: light;
  color: gray;
  font-size: 0.8rem;
  display: block;
  text-align: center;
}

.media_copyIcon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff; /* White icon */
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  padding: 5px;
  border-radius: 50%;
}